// Colors
$white: #ffffff;
$black: #000000;
$black-lighter: #2f2f2f;
$black-lightest: #a1a1a1;

$text: #2f2f2f;
$text-lighten: #a1a1a1;
$text-darken: #42382e;
$text-darken-more: #2f2f2f;

// Theme
$soft-yellow: #fffaca;

// Blue grays (hue 222)
$gray: #d2d7e5;
$gray-lighten: #f0f1f5;
$gray-lighten-more: #f2f4f8;
$gray-lighten-more-more: #f8f9fc;
$gray-darken: #b9becb;
$gray-border: #e4e8f1;
$gray-border-ui: #dddddd;

// Yellow/warm grays (hue 51)
$warm-gray: #e5e2d2;
$warm-gray-lighten: #f5f4f0;
$warm-gray-lighten-more: #f8f7f2;
$warm-gray-lighten-more-more: #fcfbf8;
$warm-gray-darken: #cbc8b9;
$warm-gray-border: #f1efe5;

// Alpaca monochromatic
$yellow: #fcd305;
$yellow-dark: #e7c503;
$yellow-darker: #cead07;
$yellow-light: #fff7cc;

$brown: #947120;
$brown-dark: #42382e;
$brown-darker: #373525;
$brown-darkest: #212016;
$brown-light: #d0cbab;
$brown-lightest: #edebde;

// Chart colors
$chart-1: #ffd700;
$chart-2: #fbf2a2;
$chart-3: #baa134;

// Alpaca green because I guess we have that now
$green: #27e272;
$green-dark: #04963f;
$green-light: #4df38f;
$green-lighter: #79efa8;
$green-lightest: #d4f9e3;

// Extremes (good for subtle highlights)
$brown-black: #1c1b12;
$brown-white: #f8f7f2;

// Sidebar
$menuBg: #373525;
// $menuBg-darken: #212016;
$menuBg-darken: #1c1b12; // #000000;

// Other colors
$blue: #0088ff;
$blue-darken: #0072d6;

// Accent colors
$default: #acb7bf;
$primary: #fcd305; // #0190fe;
$secondary: #6a7a84;
$success: #46be8a;
$info: #0887c9;
$warning: #f39834;
$danger: #fb434a;

// Font Family
$base__font-family: "Inter var", sans-serif !important;

// Font Size
$base__font-size: 13 !default;

// Convert value rem() sass mixin
@function rem($px, $base: $base__font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // comment code above and uncomment below for change REMs to PXs
  //@return #{$px}px;
}

// Transitions
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}
@mixin transition-very-slow() {
  transition: all 0.4s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$md-min-width: 992px;
$md-max-width: 991px;
$sm-min-width: 768px;
$sm-max-width: 767px;
$xs-min-width: 576px;
$xs-max-width: 575px;

// shadows
$shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
$box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
$border: 1px rgba(0, 0, 0, 0.15) solid;
