@import "../../mixins";

/////////////////////////////////
/* Dashboard specific styles */

.no-margin {
  margin: 0 !important;
}

.topbar {
  img.brand-logo {
    height: 33px;
    margin-top: -4px;
  }
}

// Any component/page sitting in the dashboard should use this as it includes some padding
// Note: this is the same as .utils__content which was used previously by <Page> component
div.dashboard-container {
  padding: 0rem;
}

.ant-breadcrumb-link > a {
  font-weight: 15px !important;
}

.ant-breadcrumb > span:last-child {
  font-weight: 500;
}

.ant-dropdown,
.ant-dropdown-placement-bottomRight {
  top: 75px !important;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  color: #1b1d1f;
  background-color: rgba(189, 197, 209, 0.2);
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  margin: -0.62rem -1.54rem;
  color: #1e2022;
  font-size: 0.8125rem;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  line-height: 21px;
  color: #1e2022;
  padding: 0.5rem 1rem;
  margin: auto 1rem auto 1rem;
}

.ant-dropdown-menu {
  box-shadow: 0 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  border: none;
}

.ant-dropdown {
  color: #1e2022;
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  background-color: rgba(231, 234, 243, 0.8) !important;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-top: 0.1rem solid rgba(231, 234, 243, 0.8);
}

// TODO: make this work
//div.dashboard-container {
//  max-width: 1800px;
//}
//
//.ant-layout-content {
//  display: flex;
//  justify-content: center;
//}

// Plain dashboard title (without using the section with rule)
h1.dashboard-title {
  margin-bottom: (2rem);
  font-weight: 600;
}

div.dashboard-title-section {
  padding-bottom: 30px;
  border-bottom: 1px solid $gray;
  margin-bottom: 30px;

  h1.dashboard-title {
    margin-bottom: 2px;
  }
}

h3.dashboard-subtitle {
  font-size: 16px;
  font-weight: normal;
}

h5.api-info {
  display: block;
  float: left;
  margin-bottom: 0;
  font-size: 1rem;
  font-family: Inter var, sans-serif;
}

// Looks like the legacy <Caption> which used styled components. It uses Gilroy-Light.
h2.card-title {
  font-family: "Gilroy-Light", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #152430;
  margin-bottom: 7px;
}

// Getting started dashboard card is special with its padding in order to maximize space
// for iframe. However, the title should be padded the same way as other cards.
// It also floats left for the close button to float to the right.
h2.getting-started-card-title {
  float: left;
  padding-left: 20px;
}

a.getting-started-card-close {
  float: right;
  margin-right: 6px;
}

// When the disclosure is put within the dashboard's gray background area
.inline-disclosure {
  margin-top: 40px;
  display: block;

  p,
  small,
  a {
    color: #a1a1a1;
  }

  a {
    font-weight: 800;
  }

  a:hover {
    text-decoration: underline;
  }
}

.referral-banner {
  background-color: white;
  border-radius: 40px;
  display: inline-flex;
  align-items: center;
  padding: 15px 25px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 16px;
  line-height: 1;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}

.referral-banner-icon {
  width: 27px;
  transform: scaleX(-1);
}

.referral-modal-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Gilroy-Bold;
}

.referral-modal-title {
  font-size: 24px;
  margin-bottom: 0;
  line-height: 1.43;
}

.referral-modal-subtitle {
  font-family: Gilroy-Bold;
  font-size: 16px;
  margin-top: 15px;
  line-height: normal;
}

.referral-qualifiers {
  margin-top: 15px;
  padding-left: 15px;
  line-height: 1.5em;
}

.referral-modal-agreement {
  font-size: 12px;
  font-family: Gilroy-RegularItalic;
  margin: 30px auto 0;
}

.referral-rounded {
  border-radius: 5px;
}
