body,
.ant-layout {
  // do not remove, ask @117 if you need to change this
  // you will break onboarding layout if you do
  height: 100vh;
}

* {
  scrollbar-width: none !important;
  font-family: Inter var, sans-serif !important;
}

#chakra-toast-portal {
  z-index: 2147483647 !important;
  position: absolute !important;
}

.pagination {
  display: inherit;
}

.chakra-skeleton {
  opacity: 0.2 !important;
}

.ant-select-selection-selected-value {
  display: flex !important;
}

.ant-select-dropdown-menu-item {
  display: flex !important;
}

::-webkit-scrollbar {
  display: none !important;
}

.alpaca-component--table-wrapper {
  margin-bottom: 0 !important;
}

.onfido-sdk-ui-Theme-modalOverlay--after-open {
  // assign highest z-index
  z-index: 2147483647;
}

select:invalid {
  color: var(--chakra-colors-gray-50);
}

* {
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}

[data-privacy="false"] {
  transition: all 0.4s ease-in-out;
}

button:focus {
  outline: none;
  box-shadow: none !important;
}

[data-privacy="true"] {
  transition: all 0.4s ease-in-out;
  -webkit-filter: blur(12px);
  -moz-filter: blur(12px);
  -o-filter: blur(12px);
  -ms-filter: blur(12px);
  filter: blur(12px);
}

.blur {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.blur-heavy {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

.flag-dropdown {
  border: none !important;
  font-size: 1rem !important;
}

.selected-flag,
.selected-flag.open,
.selected-flag:hover {
  border: none !important;
  background: var(--chakra-colors-gray-20) !important;
  border-top-left-radius: var(--chakra-radii-md) !important;
  border-bottom-left-radius: var(--chakra-radii-md) !important;
  font-size: 1rem !important;
}

.react-tel-input input {
  background: var(--chakra-colors-gray-20) !important;
  font-size: 1rem !important;
}

.react-tel-input .country-list > .country:hover {
  background: var(--chakra-colors-gray-20);
}

.react-tel-input .country-list {
  font-family: Inter var, sans-serif;
  font-size: 1rem !important;
  box-shadow: none !important;
  background: var(--chakra-colors-gray-15) !important;
  border-radius: var(--chakra-radii-md);
}

.quick-trade-temp-wrapper {
  font-family: Inter var;

  .ant-row:nth-of-type(2) > .ant-col-12:nth-of-type(1) {
    width: calc(50% - 1rem);
    margin-right: 1rem;
  }

  .ant-radio > span {
    font-size: 1rem !important;
  }

  input {
    font-size: var(--chakra-fontSizes-md);
    margin-top: 0.5rem;
    color: var(--chakra-colors-gray-100);
    border: none;
    border: 1px solid transparent;
    width: 100%;
    padding-inline-start: var(--chakra-space-1);
    padding-inline-end: var(--chakra-space-10);
    outline-offset: 2px;
    height: var(--chakra-sizes-9);
    border-bottom: 2px solid var(--chakra-colors-gray-20);
  }
  input::placeholder {
    color: var(--chakra-colors-gray-60) !important;
    opacity: 1;
  }
}
.quick-trade-temp-buy-tab,
.quick-trade-temp-sell-tab {
  font-family: Inter var, sans-serif !important;
  font-weight: 500;
  transition: all 0.15s ease-in;
  margin-bottom: 1rem;
  font-size: 1rem !important;
}

.quick-trade-temp-order-type {
  font-family: Inter var, sans-serif !important;
  padding-bottom: 0.5rem;
  font-weight: 500 !important;
}

[class*="styled__InputHeading"] {
  font-family: Inter var, sans-serif !important;
  font-weight: 500 !important;
}

.quick-trade-temp-quote,
.quick-trade-temp-symbol {
  font-size: 14px;
  font-family: Inter var, sans-serif !important;
  font-weight: 400 !important;
  margin-top: 0.2rem !important;
}

.quick-trade-temp-radio {
  font-size: 13px;
  font-weight: 400;
  color: rgba(37, 37, 37, 0.8);
}

.quick-trade-temp-review-button {
  width: 100% !important;
  background: var(--chakra-colors-yellow-40);
  font-weight: 600 !important;
  color: black !important;
  border-radius: var(--chakra-radii-md) !important;
  font-size: 0.9rem !important;
}

.quick-trade-temp-review-button[disabled] {
  opacity: 0.5 !important;
}

.fade-in {
  -webkit-animation: fadein 0.5s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s ease-in-out; /* Firefox < 16 */
  -ms-animation: fadein 0.5s ease-in-out; /* Internet Explorer */
  -o-animation: fadein 0.5s ease-in-out; /* Opera < 12.1 */
  animation: fadein 0.5s ease-in-out;
}

#progress {
  background: var(--chakra-colors-gray-20);
  div {
    background-color: var(--chakra-colors-yellow-50);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-up-slowest {
  animation-name: fadeInUp;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.7s;
  -webkit-animation-duration: 0.7s;
}

.fade-in-up-slower {
  animation-name: fadeInUp;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
}

.fade-in-up-slow {
  animation-name: fadeInUp;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
}

.fade-in-up-medium {
  animation-name: fadeInUp;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.4s;
  -webkit-animation-duration: 0.4s;
}

.fade-in-up-fast {
  animation-name: fadeInUp;
  animation-fill-mode: both;
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
}

.fade-in-up-faster {
  animation-name: fadeInUp;
  animation-fill-mode: both;
  animation-duration: 0.2s;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
}

.fade-in-up-fastest {
  animation-name: fadeInUp;
  animation-fill-mode: both;
  animation-duration: 0.1s;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 0.1s;
}

.fade-in-right-slowest {
  animation-name: fadeInRight;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.7s;
  -webkit-animation-duration: 0.7s;
}

.fade-in-right-slower {
  animation-name: fadeInRight;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
}

.fade-in-right-slow {
  animation-name: fadeInRight;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
}

.fade-in-right-medium {
  animation-name: fadeInRight;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  -webkit-animation-fill-mode: both;
  animation-duration: 0.4s;
  -webkit-animation-duration: 0.4s;
}

.fade-in-right-fast {
  animation-name: fadeInRight;
  animation-fill-mode: both;
  animation-duration: 0.3s;
  -webkit-animation-name: fadeInRight;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
}

.fade-in-right-faster {
  animation-name: fadeInRight;
  animation-fill-mode: both;
  animation-duration: 0.2s;
  -webkit-animation-name: fadeInRight;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
}

.fade-in-right-fastest {
  animation-name: fadeInRight;
  animation-fill-mode: both;
  animation-duration: 0.1s;
  -webkit-animation-name: fadeInRight;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 0.1s;
}

@keyframes fadeInUp {
  0% {
    transform: translate3d(0, 12px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    -webkit-transform: translate3d(0, 1612, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  0% {
    transform: translate3d(-8px, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    -webkit-transform: translate3d(-8px, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.ant-btn-secondary {
  background-color: #fff !important;
  border: 1px solid var(--chakra-colors-gray-20)  !important;
  font-weight: 500  !important;
}

.flash--red {
  animation: flashRed .8s;
}

.flash--green {
  animation: flashGreen .8s;
}

@keyframes flashRed {
  0% {
    background-color: #ffc0c055;
  }
  100% {
    background-color: #ffffff;
  }
}

@keyframes flashGreen {
  0% {
    background-color: #bef4e655;
  }
  100% {
    background-color: #ffffff;
  }
}

::-moz-selection {
  color: inherit !important;
  background: #FEF08A !important;
}

::selection {
  color: inherit !important;
  background: #FEF08A !important;
}


:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'Inter var', sans-serif; }
}

.debug * {
  outline: 1px solid red;
}